import React, { createContext, useContext, useState, useMemo } from 'react'

const LuminanceContext = createContext()

function useLuminance () {
  return useContext(LuminanceContext)
}

function LuminanceProvider (props) {
  const [luminance, setLuminance] = useState(1)
  const value = useMemo(() => {
    return [luminance, setLuminance]
  }, [luminance])

  return <LuminanceContext.Provider value={value} {...props}/>
}

export { LuminanceProvider, useLuminance }
