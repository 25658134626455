import React from 'react'
import { createGlobalStyle } from 'styled-components'
import Reset from './reset'
import { fonts, colors, device, transitions } from './config'

const Base = createGlobalStyle`
  html, body {
    font-size: 16px;
    line-height: 1.625;
    letter-spacing: 0.6px;
    background-color: white;
    /* font-family: ${ fonts.default }, sans-serif; */
    color: ${ colors.primary };
    height: 100%;
    width: 100%;

    @media ${ device.md } {
      font-size: 15px;
    }

    @media ${ device.sm } {
      font-size: 14px;
    }
  }

  #___gatsby {
    overflow-x: hidden;
  }

  h1 {
    font-family: 'Britannic-Bol';
    font-weight: normal;
    font-size: 3.75rem;
    line-height: 1.08;
    letter-spacing: 0.53px;
    margin-bottom: 2.5rem;

    @media ${ device.lg } {
      font-size: 3.25rem;
    }

    @media ${ device.md } {
      font-size: 3rem;
    }

    @media ${ device.sm } {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-family: 'Britannic-Bol';
    font-weight: normal;
    font-size: 2.375rem;
    line-height: 1.131;
  }

  h3 {
    font-family: 'Britannic-Bol';
    font-weight: normal;
    font-size: 1.75rem;
    line-height: 1.535;
  }

  h4 {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 2.214;
    letter-spacing: 3.27px;
    text-transform: uppercase;

    @media ${ device.lg } {
      font-size: 0.8rem;
    }

    @media ${ device.sm } {
      font-size: 0.875rem;
    }
  }

  a {
    text-decoration: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    color: ${ colors.secondary };
    transition: color 0.3s;

    @media ${ device.hover } {
      &:hover {
        color: ${ colors.primary };
      }
    }
  }

  * { 
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  input,
  input[type="text"],
  textarea {
    -webkit-user-select:text;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .navigation-transition-enter,
  .navigation-transition-appear,
  .navigation-transition-initial {
    transform: translate3d(0,-100%,0);
  }

  .navigation-transition-enter-done,
  .navigation-transition-appear-done {
    transition: transform ${ transitions.navigation }ms ease-in-out;
    transform: none;
  }

  .page-transition-initial {
    opacity: 0;
  }

  .page-transition-enter,
  .page-transition-appear {
    position: absolute;
    opacity: 0;
  }

  .page-transition-enter-done {
    transition: opacity ${ transitions.page }ms ease-in-out;
    opacity: 1;
  }

  .page-transition-appear-done {
    transition: opacity ${ transitions.appear }ms ease-in-out;
    opacity: 1;
  }

  .page-transition-exit {
    transition: opacity ${ transitions.page }ms ease-in-out;
    opacity: 0;
  }
`

const GlobalStyles = () => (
  <>
    <Reset/>
    <Base/>
  </>
)

export default GlobalStyles
