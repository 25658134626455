import React from 'react'
import PropTypes from 'prop-types'
// import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../components/Seo'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import Cookie from '../components/Cookie'
import GlobalStyles from '../styles/globalStyles'

import styled, { ThemeProvider, css } from 'styled-components'
import { ModalProvider, BaseModalBackground } from 'styled-react-modal'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { LuminanceProvider } from '../hooks/luminanceContext'
import { pageWidth, breakpoints, device, colors, transitions } from '../styles/config'

const PageWrapper = styled.div`
  position: relative;
`

const theme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1.875, // rem
    outerMargin: 7.5, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 90 // rem
    },
    breakpoints: breakpoints
  },
  paddingTop: css`
    padding-top: 9.375rem;

    @media ${ device.sm } {
      padding-top: 3.125rem;
    }
  `,
  wrapper: css`
    width: 100%;
    max-width: ${ pageWidth };
    margin: 0 auto;
    padding-left: 7.5rem;
    padding-right: 7.5rem;

    @media ${ device.lg } {
      padding-left: 4.5rem;
      padding-right: 4.5rem;
    }

    @media ${ device.md } {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  `,
  backgroundTop: css`
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      top: -300px;
      left: 0;
      right: 0;
      height: 300px;
      background-color: ${ colors.neutralLight }
    }
  `
}

const ModalBackground = styled(BaseModalBackground)`
  opacity: ${ props => props.opacity };
  transition: opacity ease 500ms;
  background-color: rgba(36,41,70,0.95);
  padding: 1.875rem;

  @media ${ device.md } {
    padding: 0.5rem;
  }
`

const Layout = ({ children, location }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <GlobalStyles/>
      <SEO location={location}/>
      <ThemeProvider theme={theme}>
        <ModalProvider backgroundComponent={ModalBackground}>
          <LuminanceProvider>
            <Navigation currentPathname={location.pathname}/>
            <TransitionGroup>
              <CSSTransition
                key={location.pathname}
                classNames="page-transition"
                appear
                timeout={{
                  enter: transitions.page,
                  exit: transitions.page,
                  appear: transitions.appear
                }}
                onExited={(a, b, c) => { window.scrollTo(0, 0) }}
              >
                <div className="page-transition-initial">
                  <PageWrapper>
                    {children}
                  </PageWrapper>
                  <Footer/>
                  <Cookie/>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </LuminanceProvider>
        </ModalProvider>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
