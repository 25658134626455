import React from 'react'
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'

import AddressSvg from '../images/footer-address.svg'
import GettingThereSvg from '../images/footer-getting-there.svg'
import ParkingSvg from '../images/footer-parking.svg'
import OpeningTimesSvg from '../images/footer-opening-times.svg'
import ReservationsSvg from '../images/footer-reservations.svg'

import { colors, device } from '../styles/config'

const Container = styled.footer`
  padding: 4rem 0;
  position: relative;
  overflow: hidden;
  
  @media ${ device.sm } {
    padding: 0;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  
  ${ props => props.theme.wrapper }

  &:after {
    display: block;
    content: '';
    height: 3px;
    width: 200%;
    bottom: 0;
    left: -50%;
    position: absolute;
    background-color: ${ colors.highlight };
  }
`

const Column = styled(Col)`
  font-size: 0.75rem;
  line-height: 1.75
  letter-spacing: 0.45px;

  @media ${ device.md } {
    font-size: 0.59rem;
  }

  @media ${ device.sm } {
    font-size: 0.75rem;
  }

  svg {
    position: absolute;
    bottom: 3rem;

    @media ${ device.sm } {
      position: initial;
      bottom: initial;
      margin-bottom: 0.5rem;
    }
  }

  p:empty, span:empty {
    display: block;
    margin-bottom: 1rem;
  }
`

const FooterNavigation = styled(Column)`
  padding-right: 0;

  @media ${ device.sm } {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-right: 0;
  }
`

const FooterItem = styled(Column)`
  @media ${ device.sm } {
    display: flex;
    margin-top: 3rem;
    padding: 0;
  }
`

const Header = styled.div`
  font-weight: bold;
  line-height: 4.6;
  text-transform: uppercase;
  margin-top: 3.5rem;
  margin-bottom: 1.15rem;
  position: relative;

  @media ${ device.sm } {
    margin: 0;
    position: initial;
    line-height: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 40%;
  }
`

const Link = styled(GatsbyLink)`
  font-size: 0.75rem;
  line-height: 4.67;
  letter-spacing: 2.4px;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  color: ${ colors.primary };
  transition: color 0.3s;

  @media ${ device.hover } {
    &:hover {
      color: ${ colors.secondary };
    }
  }

  @media ${ device.md } {
    font-size: 0.59rem;
  }

  @media ${ device.sm } {
    font-size: 0.75rem;
  }

  @media ${ device.sm } {
    margin: 0 0.9rem;
  }
`

const Footer = () => {
  const {
    datoCmsFooter: {
      address,
      gettingThere,
      parking,
      openingTimes,
      reservations
    }
  } = useStaticQuery(footerQuery)

  return (
    <Container>
      <Row>
        <FooterNavigation xs={12} sm={2}>
          <Link to="/">Kultur</Link>
          <Link to="/vermietung">Vermietung</Link>
          <Link to="/gastro">Gastro</Link>
          <Link to="/jobs">Jobs</Link>
          <Link to="/bootsverleih">Bootsverleih</Link>
          <Link to="/ueber-uns">Über Uns</Link>
          <Link to="/kontakt">Kontakt</Link>
          <Link to="/datenschutz">Datenschutz</Link>
          <Link to="/impressum">Impressum</Link>
        </FooterNavigation>
        <FooterItem xs={12} sm={2}>
          <Header>
            <AddressSvg/>
            Adresse
          </Header>
          <div dangerouslySetInnerHTML={{ __html: address }}/>
        </FooterItem>
        <FooterItem xs={12} sm={2}>
          <Header>
            <GettingThereSvg/>
            Anfahrt
          </Header>
          <div dangerouslySetInnerHTML={{ __html: gettingThere }}/>
        </FooterItem>
        <FooterItem xs={12} sm={2}>
          <Header>
            <ParkingSvg/>
            Parkplätze
          </Header>
          <div dangerouslySetInnerHTML={{ __html: parking }}/>
        </FooterItem>
        <FooterItem xs={12} sm={2}>
          <Header>
            <OpeningTimesSvg/>
            Öffnungszeiten
          </Header>
          <div dangerouslySetInnerHTML={{ __html: openingTimes }}/>
        </FooterItem>
        <FooterItem xs={12} sm={2}>
          <Header>
            <ReservationsSvg/>
            Reservierung
          </Header>
          <div dangerouslySetInnerHTML={{ __html: reservations }}/>
        </FooterItem>
      </Row>
    </Container>
  )
}

const footerQuery = graphql`
  query footerQuery {
    datoCmsFooter {
      address
      gettingThere
      parking
      openingTimes
      reservations
    }
  }
`

export default Footer
