// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-bootsverleih-js": () => import("./../../../src/pages/bootsverleih.js" /* webpackChunkName: "component---src-pages-bootsverleih-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-gastro-js": () => import("./../../../src/pages/gastro.js" /* webpackChunkName: "component---src-pages-gastro-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-vermietung-js": () => import("./../../../src/pages/vermietung.js" /* webpackChunkName: "component---src-pages-vermietung-js" */),
  "component---src-templates-room-js": () => import("./../../../src/templates/room.js" /* webpackChunkName: "component---src-templates-room-js" */)
}

