import React, { memo } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import config from '../../config/website'

const SEO = ({ location }) => {
  const { site: { buildTime }, datoCmsSite: { globalSeo } } = useStaticQuery(graphql`
    query SeoQuery {
      site {
        buildTime(formatString: "YYYY-MM-DD")
      }
      datoCmsSite {
        globalSeo {
          siteName
          fallbackSeo {
            title
            description
            twitterCard
            image {
              url
            }
          }
        }
      }
    }
  `)

  const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix
  const homeURL = `${ config.siteUrl }${ realPrefix }`

  const title = globalSeo.siteName || config.siteTitle
  const description = globalSeo.fallbackSeo.description || config.siteDescription
  const image = `${ homeURL }/${ config.image }`
  const { twitterCard } = globalSeo.fallbackSeo

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: homeURL,
    headline: description,
    inLanguage: 'en',
    mainEntityOfPage: homeURL,
    description: description,
    name: title,
    author: {
      '@type': 'Person',
      name: title,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: title,
    },
    copyrightYear: new Date().getYear(),
    creator: {
      '@type': 'Person',
      name: title,
    },
    publisher: {
      '@type': 'Person',
      name: title,
    },
    datePublished: '2019-07-01',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: image,
    },
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': homeURL,
        name: 'Homepage',
      },
      position: 1,
    },
  ]

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }

  const facebookSeo = [
    { property: 'og:locale', content: config.ogLanguage },
    { property: 'og:url', content: homeURL },
    { property: 'og:site_name', content: title },
    { property: 'og:title', content: title },
    { property: 'og:type', content: 'website' },
    { property: 'og:description', content: description },
    { property: 'og:image', content: image },
    { property: 'og:image:alt', content: description },
  ]

  const twitterSeo = [
    { name: 'twitter:card', content: twitterCard },
    { name: 'twitter:url', content: homeURL },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: image },
    { name: 'twitter:image:alt', content: description },
  ]

  return (
    <Helmet
      title={title + getTitle(location)}
      htmlAttributes={{ lang: config.siteLanguage }}
      meta={[
        { name: title, content: description },
        { name: 'description', content: description },
        { name: 'keywords', content: config.keywords },
        { name: 'image', content: image },
        { name: 'msapplication-TileColor', content: config.backgroundColor },
        { name: 'msapplication-config', content: 'browserconfig.xml' },
        ...facebookSeo,
        ...twitterSeo
      ]}
      link={[
        { rel: 'apple-touch-icon', href: '/apple-touch-icon.png' },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
        { rel: 'shortcut icon', href: '/favicon.ico' },
      ]}
    >
      <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>

    </Helmet>
  )
}

function getTitle (location) {
  const current = location.pathname

  switch (current) {
  case '/':
    return ''
  case '/vermietung':
    return ' | Vermietung'
  case '/vermietung/wiese':
    return ' | Wiese'
  case '/vermietung/theaterdeck':
    return ' | Theaterdeck'
  case '/vermietung/brueckendeck':
    return ' | Brückendeck'
  case '/vermietung/sonnendeck':
    return ' | Sonnendeck'
  case '/gastro':
    return ' | Gastro'
  case '/ueber-uns':
    return ' | Über uns'
  case '/bootsverleih':
    return ' | Bootsverleih'
  case '/kontakt':
    return ' | Kontakt'
  case '/datenschutz':
    return ' | Datenschutz'
  case '/impressum':
    return ' | Impressum'
  default:
    return ''
  }
}

export default memo(SEO)
