/* eslint-disable max-len */
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { colors, device } from '../styles/config'

const Container = styled.div`
  display: ${ props => props.showCookie ? 'flex' : 'none' };
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: ${ colors.neutralLight };
  border-top: 3px solid ${ colors.primary };
  padding: 2rem;
  flex-direction: row;
  z-index: 9;

  @media ${ device.sm } {
    flex-direction: column;
    padding: 1rem;
  }

  p {
    width: 100%;
    margin-right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${ device.sm } {
      margin-bottom: 1rem;
      font-size: 0.8rem;
    }
  }

  > div {
    display: flex;
    align-items: flex-start;

    @media ${ device.sm } {
      justify-content: flex-end;
    }

    > a {
      cursor: pointer;
      margin-right: 2rem;
      margin-bottom: 0;

      @media ${ device.sm } {
        margin-right: 1rem;
        font-size: 0.8rem;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const Cookie = () => {
  const [showCookie, toggleCookie] = useState(false)

  useEffect(() => {
    toggleCookie(typeof window !== 'undefined' && window.localStorage.userAcceptsCookies !== '1')
  }, [])

  function onCookieBannerClick () {
    window.localStorage.userAcceptsCookies = 1
    toggleCookie(false)
  }

  return (
    <Container showCookie={showCookie}>
      <p>
        Durch die Nutzung unserer Website stimmen Sie der Verwendung von Cookies zur Verbesserung des Online-Erlebnisses zu.
      </p>
      <div>
        <a onClick={() => onCookieBannerClick()}>OK</a>
        <Link to='/datenschutz'>WEITERLESEN</Link>
      </div>
    </Container>
  )
}

export default Cookie
