import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { useLuminance } from '../hooks/luminanceContext'
import { colors, device, headerHeight, transitions } from '../styles/config'
import MobileMenuSvg from '../images/mobileMenu.svg'
import CloseSvg from '../images/close.svg'
import Logo from '../images/logo.svg'
import LogoSmall from '../images/logo-small.svg'

const Navigation = ({ active, currentPathname }) => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)
  const [luminance] = useLuminance()

  const color = getColor(currentPathname === '/' && luminance)

  function toggleMobileMenu () {
    setMobileMenuVisible(visible => !visible)
  }

  function hideMobileMenu () {
    setMobileMenuVisible(false)
  }

  return (
    <>
      <TransitionGroup>
        <CSSTransition
          appear
          classNames="navigation-transition"
          timeout={transitions.navigation}
        >
          <Container
            color={color}
            className="navigation-transition-initial"
          >
            <NavigationHome
              color={color}
              onClick={() => {
                hideMobileMenu()
                navigate('/')
              }}
              as="div"
            >
              <Logo/>
              <LogoSmall/>
            </NavigationHome>
            <MobileMenu
              onTouchStart={toggleMobileMenu}
              mobileMenuVisible={mobileMenuVisible}
              color={color}
            >
              <MobileMenuSvg/>
            </MobileMenu>
            <MobileContainer mobileMenuVisible={mobileMenuVisible}>
              <CloseButton onTouchStart={toggleMobileMenu}/>
              <NavigationItem
                to="/"
                activeClassName="navigation-item-active"
                color={color}
                onClick={hideMobileMenu}
              >
          Kultur
              </NavigationItem>
              <NavigationItem
                to="/vermietung"
                partiallyActive={true}
                activeClassName="navigation-item-active"
                color={color}
                onClick={hideMobileMenu}
              >
          Vermietung
              </NavigationItem>
              <NavigationItem
                to="/gastro"
                partiallyActive={true}
                activeClassName="navigation-item-active"
                color={color}
                onClick={hideMobileMenu}
              >
          Gastro
              </NavigationItem>
              <NavigationItem
                to="/jobs"
                partiallyActive={true}
                activeClassName="navigation-item-active"
                color={color}
                onClick={hideMobileMenu}
              >
          Jobs
              </NavigationItem>
              <NavigationItem
                to="/ueber-uns"
                partiallyActive={true}
                activeClassName="navigation-item-active"
                color={color}
                onClick={hideMobileMenu}
              >
          Über uns
              </NavigationItem>
            </MobileContainer>
          </Container>
        </CSSTransition>
      </TransitionGroup>
      <MobileOverlay mobileMenuVisible={mobileMenuVisible} onTouchStart={toggleMobileMenu}/>
    </>
  )
}

const Container = styled.nav`
  height: ${ headerHeight.desktop };
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 3;
  position: relative;
  ${ props => props.theme.wrapper }

  @media ${ device.sm } {
    height: ${ headerHeight.mobile };
  }
`

const NavigationItem = styled(Link)`
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: 3.49px;
  text-transform: uppercase;
  transition: color 0.3s;
  margin-right: 2.8rem;
  color: ${ props => props.color };
  position: relative;

  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    left: 0%;
    height: 2px;
    top: 1.675rem;
    transition: background-color 0.3s, transform 0.3s;
    background-color: ${ props => props.color };
    transform: scaleX(0);
    transform-origin: left;
  }

  @media ${ device.xl }  {
    margin-right: 2rem;
  }

  @media ${ device.lg }  {
    margin-right: 2.8rem;
  }

  @media ${ device.md }  {
    margin-right: 0
  }

  &:last-child {
    margin-right: 0;
  }

  @media ${ device.hover } {
    &:hover {
      color: ${ colors.secondary };
    }
  }


  @media ${ device.sm } {
    svg {
      g {
        transition: fill 0.75s;
        fill: ${ props => props.color };
      }
    }
  }

  &.navigation-item-active {
    &:after {
      transform: scaleX(1);
      transform-origin: right;
    }

    @media ${ device.hover } {
      &:hover {
        &:after {
          background-color: ${ colors.secondary };
        }
      }
    }
  }
`

const NavigationHome = styled(NavigationItem)`
  margin-right: auto;
  cursor: pointer;
  svg {
    g {
      fill: ${ colors.primary }
    }
  }

  svg:first-child {
    width: 140px;
    height: auto;

    @media ${ device.md } {
      display: none;
    }
  }

  svg:last-child {
    width: 183px;
    height: 41px;
    display: none;

    @media ${ device.md } {
      display: block;
    }
  }

  @media ${ device.xl } {
    margin-right: auto;
  }

  @media ${ device.lg } {
    margin-right: auto;
  }

  @media ${ device.md } {
    margin-right: auto;
  }

  @media ${ device.sm } {
    svg {
      g {
        transition: fill 0.75s;
        fill: ${ props => props.color };
      }
    }
  }

  @media ${ device.hover } {
    svg {
      g {
        transition: fill 0.3s;
      }
    }
    &:hover {
      svg {
        g {
          fill: ${ colors.secondary };
        }
      }
    }
  }
`

const MobileMenu = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 2rem;
  width:  32px;
  /* z-index: 4; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: ${ headerHeight.mobile };

  svg {
    rect {
      transition: fill 0.75s;
      fill: ${ props => props.mobileMenuVisible ? colors.primary : props.color };
    }

    /* #line-1,
    #line-2,
    #line-3 {
      transition: transform 0.5s;
    }

    #line-1 {
      transform-origin: 100% 0%;
      transform: ${ props => props.mobileMenuVisible && 'rotate(-45deg)' };
    }

    #line-2 {
      transform-origin: 100% 50%;
      transform: ${ props => props.mobileMenuVisible && 'scaleX(0)' };
    }

    #line-3 {
      transform-origin: 100% 100%;
      transform: ${ props => props.mobileMenuVisible && 'rotate(45deg) scaleX(0.835)' };
    } */
  }

  @media ${ device.sm } {
    display: flex;
  }
`

const CloseButton = styled(CloseSvg)`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 2.5rem;
  top: 2.5rem;
  cursor: pointer;
  display: none;

  @media ${ device.sm } {
    display: block;
  }
`

const MobileContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 2rem;

  @media ${ device.md }  {
    width: 60%;
    justify-content: space-between;
  }

  @media ${ device.sm } {
    width: 57%;
    right: -57%;
    top: 0;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 3;
    padding: ${ headerHeight.mobile } 3.75rem 6rem 3.75rem;
    background: white;
    position: fixed;
    flex-direction: column;
    transition: transform 0.5s;
    transform: translate3d(${ props => props.mobileMenuVisible ? '-100%' : 0 },0,0);
    outline: 0;
    margin-left: 0;

    ${ NavigationItem } {
      margin: 0;
      margin-bottom: 4.6rem;
      color: ${ colors.primary };
    }
  }
`

const MobileOverlay = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${ colors.primary };
  z-index: 2;
  transition: opacity 0.5s, visibility 0.5s;
  opacity: ${ props => props.mobileMenuVisible ? 0.95 : 0 };
  visibility: ${ props => props.mobileMenuVisible ? 'visible' : 'hidden' };

  @media ${ device.sm } {
    display: block;
  }
`

Navigation.propTypes = {
  siteTitle: PropTypes.string,
  currentPathname: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: '',
}

function getColor (luminance) {
  if (!luminance || luminance > 0.6) {
    return colors.primary
  }

  return 'white'
}

export default Navigation
