const twoYearsFromNow = require('../src/utils/time').twoYearsFromNow

/* eslint-disable max-len */
module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"
  siteTitle: 'Kulturhaus Insel Berlin', // Navigation and Site Title
  siteTitleAlt: 'Kulturhaus Insel Berlin', // Alternative Site title for SEO
  siteTitleShort: 'Kulturhaus Insel Berlin', // short_name for manifest
  siteHeadline: 'Kulturhaus Insel Berlin', // Headline for schema.org JSONLD
  siteUrl: 'https://www.inselberlin.de', // Domain of your site. No trailing slash!
  // siteUrl: 'https://inselberlin.netlify.com', // Domain of your site. No trailing slash!
  // siteUrl: 'http://localhost:8000', // Domain of your site. No trailing slash!
  siteLanguage: 'de', // Language Tag on <html> element
  image: 'seo.jpg', // Used for SEO and manifest
  siteDescription: 'Das Kulturhaus Insel Berlin ist einer der außergewöhnlichsten Veranstaltungsorte im Herzen Berlins. Auf einer Insel im Treptower Park genießen Sie ein Stück Natur am ruhigen Ufer der Spree.',
  author: 'insel.berlin.development', // Author for schema.org JSONLD
  keyword: 'insel der jugend, insel berlin, berlin, treptower park, kultur, vermietung, bootsverleih, events, freiluftkino, konzert',
  // siteFBAppID: '123456789', // Facebook App ID - Optional
  // userTwitter: '@cara', // Twitter Username
  ogSiteName: 'Kulturhaus Insel Berlin', // Facebook Site Name
  ogLanguage: 'de_DE', // Facebook Language
  // googleAnalyticsID: 'UA-47519312-5',

  // Manifest and Progress color
  themeColor: '#6C82FF',
  backgroundColor: '#FFFFFF',

  brueckendeckUrl: `https://www.googleapis.com/calendar/v3/calendars/qh0jeq1qtk4n4it79mv9iujqkg@group.calendar.google.com/events?singleEvents=true&fields=summary%2Citems(start%2Cend)&key=AIzaSyBwKwZ3fXzuJcXDIDyJkLlhDTkk2wdD7t8&timeMin=${ encodeURIComponent(new Date().toISOString()) }&timeMax=${ twoYearsFromNow() }`,
  inselgartenUrl: `https://www.googleapis.com/calendar/v3/calendars/9ulvboo1s49dam36mcm8isv9oc@group.calendar.google.com/events?singleEvents=true&fields=summary%2Citems(start%2Cend)&key=AIzaSyBwKwZ3fXzuJcXDIDyJkLlhDTkk2wdD7t8&timeMin=${ encodeURIComponent(new Date().toISOString()) }&timeMax=${ twoYearsFromNow() }`,
  sonnendeckUrl: `https://www.googleapis.com/calendar/v3/calendars/5nbv3gsriuhdsj16us523bsv04@group.calendar.google.com/events?singleEvents=true&fields=summary%2Citems(start%2Cend)&key=AIzaSyBwKwZ3fXzuJcXDIDyJkLlhDTkk2wdD7t8&timeMin=${ encodeURIComponent(new Date().toISOString()) }&timeMax=${ twoYearsFromNow() }`,
  theaterdeckUrl: `https://www.googleapis.com/calendar/v3/calendars/monqshj21k40khoq8blc2od1n4@group.calendar.google.com/events?singleEvents=true&fields=summary%2Citems(start%2Cend)&key=AIzaSyBwKwZ3fXzuJcXDIDyJkLlhDTkk2wdD7t8&timeMin=${ encodeURIComponent(new Date().toISOString()) }&timeMax=${ twoYearsFromNow() }`,
}
