/* eslint-disable max-len */
function getMonthName (index) {
  switch (index) {
  case 0:
    return 'Jan'
  case 1:
    return 'Feb'
  case 2:
    return 'März'
  case 3:
    return 'Apr'
  case 4:
    return 'Mai'
  case 5:
    return 'Juni'
  case 6:
    return 'Juli'
  case 7:
    return 'Aug'
  case 8:
    return 'Sep'
  case 9:
    return 'Okt'
  case 10:
    return 'Nov'
  case 11:
    return 'Dez'
  }
}

function getFullDate (date) {
  const currentDate = new Date(date)
  const day = currentDate.getDate()
  const month = currentDate.getMonth() + 1
  const year = currentDate.getFullYear()

  return `${ day }.${ month }.${ year }`
}

function compareDates (startDate, endDate, selectedDate) {
  return new Date(startDate + 'T00:00:00+02:00').getTime() <= new Date(selectedDate).getTime() && new Date(selectedDate).getTime() < new Date(endDate + 'T00:00:00+02:00').getTime()
}

function twoYearsFromNow () {
  const date = new Date()
  date.setFullYear(date.getFullYear() + 2)

  return encodeURIComponent(date.toISOString())
}

export { getMonthName, getFullDate, compareDates, twoYearsFromNow }
