const pageWidth = '1440px'
const headerHeight = {
  desktop: '130px',
  mobile: '90px'
}

const transitions = {
  page: 350,
  navigation: 350,
  appear: 650
}

const colors = {
  primary: '#323D74',
  primaryDarkened: '#2a3362',
  secondary: '#44B1EC',
  secondaryDarkened: '#1a9fe7',
  highlight: '#EB6B64',
  highlightDarkened: '#e54037',
  neutralDark: '#EBEAEA',
  neutralLight: '#F5F5F7',
  calendar: '#44B1EC'
}

const fonts = {
  default: 'Roboto'
}

const breakpoints = {
  xs: 0,
  sm: 47,
  md: 63,
  lg: 75,
  xl: 85,
}

const device = {
  // xs: `(max-width:  ${ breakpoints.xs }rem)`, // 0
  // xs: `(max-width: 42rem)`, // 768
  // sm: `(max-width: ${ breakpoints.sm }rem)`, // 768
  // md: `(max-width: ${ breakpoints.md }rem)`, // 1024
  // lg: `(max-width: ${ breakpoints.lg }rem)`, // 1200
  xs: '(max-width: 42em)', // 768
  sm: `(max-width: ${ breakpoints.sm }em)`, // 768
  md: `(max-width: ${ breakpoints.md }em)`, // 1024
  lg: `(max-width: ${ breakpoints.lg }em)`, // 1200
  xl: `(max-width: ${ breakpoints.xl }em)`, // 1360
  hover: 'not all and (pointer: coarse)',
}

export { colors, fonts, pageWidth, device, breakpoints, headerHeight, transitions }
